<template>
  <table class="table table-hover table-striped">
    <thead>
    <tr>
			<th>Office Name</th>
      <th v-if="showProgrammeFields">Programme</th>
      <th>Country</th>
      <th>Product(s)</th>
      <th>Cover Type</th>
      <th>Policy Period</th>
      <th>Live</th>
      <th v-if="showHandlersInLists()">Handler</th>
      <th v-if="showHandlersInLists()">Manager</th>
      <th v-if="showPremiumDataInLists()">Premium</th>
      <th>Status</th>
			<th>Action</th>
    </tr>
    </thead>
    <tbody>
    <tr v-for="(localPolicy, index) in localPolicies" :key="index">
			<td>{{ localPolicy.issuing_office.regional_company_name }}</td>
			<td v-html="getCountryNames(localPolicy).replace(/\n/g, '<br>')"></td>
			<td v-html="getProducts(localPolicy).replace(/\n/g, '<br>')"></td>
			<td><span class="badge badge-secondary">{{ localPolicy.cover_type }}</span></td>
			<td>{{ $options.filters.formatDateUK(localPolicy.date_inception) + ' - ' + $options.filters.formatDateUK(localPolicy.date_expiry) }}</td>
      <td><live-badge :inception="localPolicy.date_inception" :expiry="localPolicy.date_expiry" /></td>
      <td v-if="showHandlersInLists()">{{ contactName(localPolicy.primary_handler_company_contact) }}</td>
      <td v-if="showHandlersInLists()">{{ contactName(localPolicy.primary_manager_company_contact) }}</td>
      <td v-if="showPremiumDataInLists()">
        <div class="row row-xs">
          <div style="width: 40px;" v-if="localPolicy.currency">
            {{ programmeCurrency(localPolicy.master_policy_id) }}
          </div>
          {{ formatThousandsMixin(calculateTotalPremium(localPolicy.lines)) }}
        </div>
      </td>
      <td>
        <status-badge :status-mask="localPolicy.status" :flag-non-admitted="localPolicy.flag_non_admitted" :policy-bind-state="localPolicy.programme.policy_bind_state_id" />
      </td>
			<td>
				<router-link
					v-b-tooltip:hover title="View Local Policy"
					:to="{name: 'Local Policy Overview', params: {programmeId: localPolicy.master_policy_id, localPolicyId: localPolicy.local_policy_id}}"
					class="name btn btn-xs btn-outline-secondary mb-0 text-sm"
				>
				View
				</router-link>
			</td>
    </tr>
    </tbody>
  </table>
</template>

<script>
import LiveBadge from "@underwriters/src/components/Global/LiveBadge";
import StatusBadge from "@underwriters/src/components/Global/StatusBadge";

import PolicyMixin from "@underwriters/src/mixins/policy.mixin";
import FeaturesMixin from "@underwriters/src/mixins/features.mixin";

export default {
  name: "LocalPolicyTable",
  components: {
    LiveBadge,
    StatusBadge
  },
  props: {
    localPolicies: {
      type: Array,
      default: () => [],
    },
  },
  mixins: [PolicyMixin, FeaturesMixin],
  methods: {
    contactName(contact) {
      if (contact === undefined) {
        return ' - ';
      }

      return contact.forename + ' ' + contact.surname;
    },
    programme(programmeId) {
      return this.$store.getters.programmes.find(programme => programme.id == programmeId);
    },
    programmeClass(programmeId) {
      const programme = this.programme(programmeId);
      return programme?.class ?? '';
    },
    programmeName(programmeId) {
      const programme = this.programme(programmeId);
      return (programme!==undefined) ? programme.name : ' - ';
    },
    programmeCurrency(programmeId) {
      return this.programme(programmeId)?.currencyCode;
    },
		getCountryNames(localPolicy) {
			return localPolicy.countries.map(c => c.country_name).join('\n');
		},
		getProducts(localPolicy) {
			return localPolicy.products.map(product => product.policy_class_caption).join('\n');
		}
  },
  computed: {
    showProgrammeFields() {
      return this.$route.name !== 'Programme Overview';
    }
  }
}
</script>
