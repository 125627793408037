<template>
  <div class="live-badge">
    <span v-if="status==='Live'" class="badge badge-success">
      Live
    </span>
    <span v-else-if="status==='Expired'" class="badge badge-secondary">
      Expired
    </span>
    <span v-else class="badge badge-secondary">
      {{ status }}
    </span>
  </div>
</template>
<script>

import FeaturesMixin from "@underwriters/src/mixins/features.mixin";

export default {
  name: 'LiveBadge',
	mixins: [FeaturesMixin],
  props: {
    inception: {
      type: String,
      required: true
    },
    expiry: {
      type: String,
      required: true
    }
  },
  computed: {
    status() {
      return this.liveTextMixin(this.inception, this.expiry);
    }
  }
}
</script>
