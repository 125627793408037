<template>
  <div class="policy-timeline mt-3">
    <!-- Start and end labels   -->
    <div class="row justify-content-between mb-1">
      <div class="col-2">
        <small>| Creation</small>
      </div>
      <div class="col-2">
        <small style="margin-left: -10px">Issuance |</small>
      </div>
    </div>
    <!--             Location pins with numbers   -->
    <div class="row">
      <div class="col">
        <div class="row">
          <div class="col">
            <div
                class="loc-pin-container"
                v-b-tooltip.top.hover="{ customClass: 'pin-tooltip'}"
                title="Instructions Outstanding"
            >
              <i class="fa fa-4x fa-map-marker"></i>
              <span class="badge badge-circle badge-primary loc-pin-number">{{ outstanding.length }}</span>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-8">
            <div class="row justify-content-center">
              <div v-for="lPol in outstanding" :key="lPol.local_policy_id" class="col-1">
                <router-link
                    :to="{name: 'Local Policy Overview', params: {localPolicyId: lPol.local_policy_id, programmeId}}"
                    class="pol-pill"
                    :class="getLpolClass(lPol)"
                    v-b-tooltip.bottom.hover="{ title: lPol.country.country_name + ' - ID ' + lPol.local_policy_id, variant: 'primary' }"
                >
                  <i class="fa fa-circle"></i>
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col">
        <div class="row">
          <div class="col">


            <div
                class="loc-pin-container"
                v-b-tooltip.top.hover="{ customClass: 'pin-tooltip'}"
                title="Instructions Finalised"
            >
              <i class="fa fa-4x fa-map-marker"></i>
              <span class="badge badge-circle badge-primary loc-pin-number">{{ finalised.length }}</span>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-8">
            <div class="row justify-content-center">
              <div v-for="lPol in finalised" :key="lPol.local_policy_id" class="col-1">
                <router-link
                    :to="{name: 'Local Policy Overview', params: {localPolicyId: lPol.local_policy_id, programmeId}}"
                    class="pol-pill"
                    :class="getLpolClass(lPol)"
                    v-b-tooltip.bottom.hover="{ title: lPol.country.country_name + ' - ID ' + lPol.local_policy_id, variant: 'primary' }"
                >
                  <i class="fa fa-circle"></i>
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col">
        <div class="row">
          <div class="col">


            <div
                class="loc-pin-container"
                v-b-tooltip.top.hover="{ customClass: 'pin-tooltip'}"
                title="Local Bind Order Sent"
            >
              <i class="fa fa-4x fa-map-marker"></i>
              <span class="badge badge-circle badge-primary loc-pin-number">{{ bindOrderSent.length }}</span>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-8">
            <div class="row justify-content-center">
              <div v-for="lPol in bindOrderSent" :key="lPol.local_policy_id" class="col-1">
                <router-link
                    :to="{name: 'Local Policy Overview', params: {localPolicyId: lPol.local_policy_id, programmeId}}"
                    class="pol-pill"
                    :class="getLpolClass(lPol)"
                    v-b-tooltip.bottom.hover="{ title: lPol.country.country_name + ' - ID ' + lPol.local_policy_id, variant: 'primary' }"
                >
                  <i class="fa fa-circle"></i>
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col">
        <div class="row">
          <div class="col">
            <div
                class="loc-pin-container"
                v-b-tooltip.top.hover="{ customClass: 'pin-tooltip'}"
                title="Local Policies Issued"
            >
              <i class="fa fa-4x fa-map-marker"></i>
              <span class="badge badge-circle badge-primary loc-pin-number">{{ issued.length }}</span>
            </div>
          </div></div>
        <div class="row">
          <div class="col-8">
            <div class="row justify-content-center">
              <div v-for="lPol in issued" :key="lPol.local_policy_id" class="col-1">
                <router-link
                    :to="{name: 'Local Policy Overview', params: {localPolicyId: lPol.local_policy_id, programmeId}}"
                    class="pol-pill"
                    :class="getLpolClass(lPol)"
                    v-b-tooltip.bottom.hover="{ title: lPol.country.country_name + ' - ID ' + lPol.local_policy_id, variant: 'primary' }"
                >
                  <i class="fa fa-circle"></i>
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col">
        <div class="row">
          <div class="col">


            <div
                class="loc-pin-container"
                v-b-tooltip.top.hover="{ customClass: 'pin-tooltip'}"
                title="Local Premiums Issued"
            >
              <i class="fa fa-4x fa-map-marker"></i>
              <span class="badge badge-circle badge-primary loc-pin-number">{{ premiumsCollected.length }}</span>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-8">
            <div class="row justify-content-center">
              <div v-for="lPol in premiumsCollected" :key="lPol.local_policy_id" class="col-1">
                <router-link
                    :to="{name: 'Local Policy Overview', params: {localPolicyId: lPol.local_policy_id, programmeId}}"
                    class="pol-pill"
                    :class="getLpolClass(lPol)"
                    v-b-tooltip.bottom.hover="{ title: lPol.country.country_name + ' - ID ' + lPol.local_policy_id, variant: 'primary' }"
                >
                  <i class="fa fa-circle"></i>
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Arrows   -->
    <div class="row my-2">
      <div class="col">
        <div class="arrow-container">
          <div class="arrow">
            <div class="line text-center"><span class="h4">Central Management</span></div>
            <div class="point"></div>
          </div>
        </div>
      </div>
      <div class="col">
        <div class="arrow-container second">
          <div class="arrow">
            <div class="line text-center"><span class="h4">Local Handling</span></div>
            <div class="point"></div>
          </div>
        </div>
      </div>
    </div>
    <!-- Labels   -->
    <div class="row mt-4">
      <div class="col">
        <span class="timeline-pill bg-on-track"></span>
        <div style="display: inline-block; padding-left: 17px;">On Track</div>
      </div>
      <div class="col">
        <span class="timeline-pill bg-problems"></span>
        <div style="display: inline-block; padding-left: 17px;">Requires Attention</div>
      </div>
    </div>
    <div class="row mt-4">
      <div class="col">
        <span class="timeline-pill bg-active"></span>
        <div style="display: inline-block; padding-left: 17px;">Active</div>
      </div>
      <div class="col">
        <span class="timeline-pill bg-expired"></span>
        <div style="display: inline-block; padding-left: 17px;">Expired</div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'PolicyTimeline',
  props: {
    programmeId: {
      type: [Number, String],
      default: -1
    },
  },
  computed: {
    outstanding() {
      return this.localPolicies.filter(lpol => lpol.flag_issued==0 && lpol.flag_full_instr_received==0);
    },
    finalised() {
      return this.localPolicies.filter(lpol => lpol.flag_issued==0 && lpol.flag_full_instr_received==1);
    },
    bindOrderSent() {
      return this.localPolicies.filter(lpol => lpol.stage===3);
    },
    issued() {
      return this.localPolicies.filter(lpol => lpol.flag_issued==1 && lpol.flag_full_instr_received==0);
    },
    premiumsCollected() {
      return this.localPolicies.filter(lpol => lpol.flag_issued==1 && lpol.flag_full_instr_received==1);
    },
    localPolicies() {
      // Annoyingly, the programme ID changes type between String and Number
      //  depending on where it is set, so we have to do a "loose equality" comparison here
      return this.$store.getters.localPoliciesForProgramme.filter(lPol => lPol.master_policy_id==this.programmeId);
    }
  },
  methods: {
    getLpolClass(lPol) {
      let cssClass = 'bg-on-track';
      if (lPol.date_expiry < Date.now()) {
        cssClass = 'bg-expired';
      } else if (lPol.flag_has_counter_quote == 1) {
        cssClass = 'bg-problems'
      } else if (lPol.flag_issued==1 && lPol.flag_full_instr_received==1) {
        cssClass = 'bg-active';
      }
      return cssClass;
    }
  }
};
</script>